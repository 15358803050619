import React, { useEffect, useState } from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { Outlet, useParams } from 'react-router-dom';
import { Col, Layout, Row, Space } from 'antd';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { messages } from './defaultMessages';
import { SupportedCodes } from './logic/supportedLanguages';
import { flattenMessages } from './logic/flattenMessages';
import { HeaderContent } from './HeaderContent';
import { MetaAttrs } from './MetaAttrs';
import { Link } from './react-router-wrappers/Link';

const { Header, Content, Footer } = Layout;

const contentStyle: React.CSSProperties = {
    minHeight: 120,
    lineHeight: '30px',
    padding: '0 20px 20px'
};

function App() {
    let { lang } = useParams();
    lang = lang || 'en';
    const [currSiteLang, setSiteLang] = useState<SupportedCodes>('en');
    const [currMessages, setMessages] = useState(messages);

    const updateResources = (newLang: SupportedCodes) => {
        setSiteLang(newLang);

        if (newLang === 'en') {
            setMessages(messages);
            return;
        }

        import((`./resources/resources-${newLang}.json`))
            .then(downloadedMessages => setMessages(flattenMessages(downloadedMessages)));
    }

    useEffect(() => {
        if (lang !== currSiteLang) {
            updateResources(lang as SupportedCodes);
        }
    }, [currSiteLang, lang])

    return (
        <IntlProvider locale={currSiteLang} key={currSiteLang} messages={currMessages}>
            <Space direction="vertical" style={{ width: '100%', minWidth: '380px' }} size={[0, 48]}>
                <Layout className="page-container">
                    <MetaAttrs/>
                    <Header
                        style={{
                            backgroundImage: 'linear-gradient(135deg, rgb(169, 37, 128), rgb(247, 188, 0) 75%)',
                            paddingInline: '0'
                        }}>
                        <HeaderContent selectedLang={currSiteLang}/>
                    </Header>
                    <Content style={contentStyle}>
                        <Outlet/>
                    </Content>
                    <Footer style={{ backgroundColor: "fff" }}>
                        <Row>
                            <Col span={4}>
                                <Link to={'privacy-policy'}>
                                    <FormattedMessage id={'footer.privacyPolicy'}/>
                                </Link>
                            </Col>
                            <Col span={4}>
                                <Link to={'blog'}>
                                    <FormattedMessage id={'footer.blog'}/>
                                </Link>
                            </Col>
                            <Col span={10}>
                                <FormattedMessage id={'footer.helpLink'}
                                                  values={{
                                                      a: chunks => <a href="mailto:support@locmat.app">{chunks}</a>
                                                  }}/>
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </Space>
        </IntlProvider>
    );
}

export default App;
